// Variables

$color-main: rgb(193,0,22);
$color-sub: rgb(0,0,0);
$color-text: rgb(75,50,25);
$color-nav: rgb(193,0,22);
$color-link: rgb(193,0,22);
$color-hover: rgb(193,0,22);

$color-100: mix(#ffffff,$color-main,80%);
$color-200: mix(#ffffff,$color-main,60%);
$color-300: mix(#ffffff,$color-main,40%);
$color-400: mix(#ffffff,$color-main,20%);
$color-500: $color-main;
$color-600: mix(#000000,$color-main,20%);
$color-700: mix(#000000,$color-main,40%);
$color-800: mix(#000000,$color-main,60%);
$color-900: mix(#000000,$color-main,80%);

$color-category-1: rgb(150,125, 50);
$color-category-2: rgb(100,150, 50);
$color-category-3: rgb( 50,150,100);
$color-category-4: rgb(100,150,200);
$color-category-5: rgb(150,100,150);

$serif: YakuHanMP_Noto,"Noto Serif JP",YuMincho,"Yu Mincho","Hiragino Mincho ProN",HGPMinchoE,serif;
$sans-serif: "Noto Sans JP",YuGothic,"Yu Gothic Medium","Hiragino Kaku Gothic ProN",Meiryo,sans-serif;
$rounded: YakuHanRP,"M PLUS Rounded 1c",$sans-serif;
$kaimin-sora-bold: "A-OTF Kaimin Sora Std","Kaimin Sora Bold";
$font-awesome: "Font Awesome 5 Free","Font Awesome 5 Brands";
$material-icons: "Material Icons";

$vw: var(--vw,100vw);
$vw-50: calc(#{$vw} / 2);

/* Bootstrap */
$primary: $color-main;
$font-family-sans-serif: $sans-serif;
// $border-radius: 0.125rem;

$grid-breakpoints: (
  xs: 0,
  sm: 512px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);

$container-max-widths: (
  sm: 480px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);




@function stripe($color-1, $color-2: transparent) {
  @return linear-gradient(45deg,
    $color-1 25%,$color-2 25.1%,
    $color-2 50%,$color-1 50.1%,
    $color-1 75%,$color-2 75.1%
  );
}
