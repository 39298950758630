// Placeholders








%nav-large {
  // @include debug;
  display: flex;
  justify-content: center;
}

%nav-large a {
  @include mq-prop(font-size,1rem,null,1.25rem);
  @include mq-prop(min-width,100%,null,30rem);
  align-items: center;
  background: #ffffff;
  box-shadow: inset 0 0 0 0.125rem currentColor;
  color: $color-text;
  display: flex;
  font-weight: 700;
  justify-content: center;
  padding: 0.75em 1em;
  position: relative;
  text-decoration: none;

  &::before {
    content: "\f15c";
    font: 700 1.5em/0 $font-awesome;
    margin-right: 0.25em;
  }
  &::after {
    @include absolute(0);
    background: currentColor;
    content: "";
    transform: translate(0.25rem,0.25rem);
    z-index: -1;
  }
  &[href$=".pdf"] {
    content: "\f1c1";
  }
  &:hover {
    color: $color-main;
  }
}

/*
.menu__origin {
  // @include debug;
  display: flex;
  justify-content: center;

  a {
    // @include debug;
    @include mq-prop(font-size,1rem,null,1.25rem);
    @include mq-prop(min-width,100%,null,50%);
    align-items: center;
    background: #ffffff;
    border: 0.125rem solid currentColor;
    box-shadow: 0.25rem 0.25rem currentColor;
    color: $color-text;
    display: flex;
    font-weight: 700;
    justify-content: center;
    padding: 0.75em 1em;
    text-decoration: none;
    text-align: center;
  }
  a::before {
    // @include debug;
    @include size(1em);
    flex-shrink: 0;
    content: "\f1c1";
    font: 700 1.5em/1 $font-awesome;
    margin-right: 0.5em;
    // padding: 0.75em;
  }
}
*/








/*  */
%nav-small a {
  align-items: center;
  background: #ffffff;
  border: 1px solid $gray-400;
  border-radius: 999rem;
  color: $color-text;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  padding: 0.25em 1em;
  text-decoration: none;
  transition: border 0.4s, color 0.4s;

  &::before,
  &::after {
    background: url("../images/nav-small-icon.svg") 50%/100%;
    filter: saturate(20%);
    margin-left: -0.75em;
    padding: 0.75em;
    transition: filter 0.4s;
  }
  &::after {
    margin: 0 -0.75em 0 0;
    transform: scaleX(-1);
  }
  &:hover {
    border-color: $color-main;
    color: $color-main;
  }
  &:hover::before,
  &:hover::after {
    filter: none;
  }
}
