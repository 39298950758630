// Mixins

// メディアクエリ
@mixin mq-min($name) {
  @include media-breakpoint-up($name) {
    @content;
  }
}

@mixin mq-max($name) {
  @include media-breakpoint-down($name) {
    @content;
  }
}

@mixin mq-prop($property, $xs, $sm, $md: null, $lg: null, $xl: null) {
  #{$property}: $xs;
  @include mq-min(sm) { #{$property}: $sm; }
  @include mq-min(md) { #{$property}: $md; }
  @include mq-min(lg) { #{$property}: $lg; }
  @include mq-min(xl) { #{$property}: $xl; }
}

// ショートハンド
@mixin edges-shorthand($values, $prefix: null) {
  @if index($values, null) or not $prefix {
    $prefix: if($prefix, #{$prefix}-, null);
    $index: nth((1 1 1, 2 1 2, 2 3 2, 2 3 4), length($values));

    #{$prefix}top   : nth($values, 1);
    #{$prefix}right : nth($values, nth($index, 1));
    #{$prefix}bottom: nth($values, nth($index, 2));
    #{$prefix}left  : nth($values, nth($index, 3));
  } @else {
    #{$prefix}: $values;
  }
}

// ボックスモデル
@mixin margin($values) {
  @include edges-shorthand($values, margin);
}

@mixin padding($values) {
  @include edges-shorthand($values, padding);
}

// 視覚整形モデル
@mixin position($values, $position: null) {
  @include edges-shorthand($values);
  position: $position;
}

@mixin static($values) {
  @include position($values, static);
}

@mixin relative($values) {
  @include position($values, relative);
}

@mixin absolute($values) {
  @include position($values, absolute);
}

@mixin sticky($values) {
  @include position($values, sticky);
}

@mixin fixed($values) {
  @include position($values, fixed);
}

@mixin size($values, $prefix: null) {
  $prefix: if($prefix, #{$prefix}-, null);
  #{$prefix}width : nth($values, 1);
  #{$prefix}height: nth($values, if(length($values) > 1, 2, 1));
}

@mixin min-size($values) {
  @include size($values, min);
}

@mixin max-size($values) {
  @include size($values, max);
}

// コンテナ
@mixin container {
  @include margin(null auto);
  @include mq-prop(max-width,null,30rem,45rem,60rem);
  width: calc(100% - 2rem);
}

// ユーティリティ
@mixin debug($color: rgb(random(255),random(255),random(255))) {
  background-color: rgba($color,0.5);
  box-shadow: inset 0 0 0 0.0625rem $color;
}
