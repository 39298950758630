/*
 * Project name: 定食屋 宮本むなし
 * URL: https://m-munashi.com/
 * Created: 2022.06.01
 */

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "functions";
@import "variables";
@import "mixins";
@import "placeholders";

/*  */
.breadcrumbs {
  display: none;
}

/*  */
.hero {
  @include mq-prop(margin,0 -1rem,0);
}

.image__list {
  @include mq-prop(max-width,30rem,none);
  margin: 0 auto 1rem;
}

.image__item {
  display: none;

  img {
    display: block;
    width: 100%;
  }
  &:first-child {
    display: block;
  }
  &[href=""] {
    pointer-events: none;
  }
}

.image__list .slick-list {
  overflow: visible;
}

.thumb__list {
  display: flex;
  margin: auto;
  max-width: 30rem;
}

.thumb__item {
  display: none;
  margin: 0;
  max-width: 7.5rem;
  padding: 0.25rem;
  width: 25vw;

  img {
    border: 1px solid $gray-400;
    cursor: pointer;
    display: block;
    transition: 0.4s;
    width: 100%;
  }
  &.slick-active img {
    border-color: $color-main;
  }
}

.thumb__list .slick-arrow {
  border: none;
  font-size: 0;
  padding: 6.25%;
}




.thumb__list .slick-arrow {
  background: url("../images/nav-small-icon.svg") 50%/1.5rem no-repeat;
  border: none;
  font-size: 0;
  padding: 6.25%;

  &.slick-next {
    transform: scaleX(-1);
  }
}




/*  */
.app {
  margin: 0 auto 4rem;
  max-width: 45rem;
}

.app__banner {
  display: block;
  margin-bottom: 1rem;

  img {
    display: block;
    width: 100%;
  }
}

.app__badge {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding-left: 0;

  li {
    padding: 0 0.5rem;
  }
  a {
    display: block;
  }
  img {
    display: block;
    height: 2.5rem;
  }
}

/*  */
.guide {
  @include mq-prop(font-size,0.875rem,null,1rem);
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  .app + & {
    margin-top: -3rem;
  }
}

.guide a {
  @include mq-prop(min-width,100%,null,75%);
  background: rgb(255,225,150);
  border-radius: 999rem;
  color: $color-text;
  font-weight: 700;
  padding: 0.75em 1.5em;
  text-align: center;
  transition: background 0.4s;

  &:hover {
    background: lighten(rgb(255,225,150),10%);
  }
}

/*  */
.about {
  margin-bottom: 8rem;
}

@include mq-max(md) {
  .about__row {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
  }
  .about__col:first-child {
    margin: 0 -1rem 2rem;
    max-width: 30rem;
  }
  .row-1 .about__image {
    margin-left: 30%;
  }
  .row-2 .about__image {
    margin-right: 30%;
  }
}

@include mq-min(md) {
  .about__row {
    display: flex;
    margin: 0 -2rem 4rem;
  }
  .row-1,
  .row-3 {
    flex-direction: row-reverse;
  }
  .about__col {
    padding: 0 2rem;
  }
  .about__col:first-child {
    flex-shrink: 0;
    width: 45%;
  }
  .row-3 .about__col:first-child {
    width: 55%;
  }
}

.about__image {
  margin: 0;
  position: relative;

  &::before,
  &::after {
    background: $color-main;
    content: "";
    padding: 2rem;
    position: absolute;
    z-index: -1;
  }
  &::after {
    background: url("../images/home/about-bg.png") 50%/1.5rem;
  }
  .row-1 &::before,
  .row-1 &::after {
    @include position(2rem 0 null -4rem);
  }
  .row-1 &::before {
    display: none;
  }
  .row-2 &::before,
  .row-2 &::after {
    @include position(null -2rem -2rem 50%);
  }
  .row-2 &::after {
    z-index: auto;
  }
  .row-3 &::before,
  .row-3 &::after {
    @include position(null 2rem -2rem 0);
  }
  img {
    display: block;
    width: 100%;
  }
}

.about__caption {
  color: $color-main;
  font-family: $kaimin-sora-bold;
  font-size: calc((100vw - 2rem) / 16);
  margin: 0 0 1rem;

  span {
    letter-spacing: 0.25em;
  }
  @media (min-width: calc(1.5rem * 16 + 2rem)) {
    @include mq-prop(font-size,1.5rem,null,null,1.75rem);
  }
}

.about__text {
  @include mq-prop(font-size,0.875rem,null,null,1rem);
  line-height: 1.75;
  margin: 0;
  text-align: justify;
}

.about__note {
  @include mq-prop(font-size,0.75rem,null,null,0.875rem);
  margin: 1rem 0 0;
  text-align: justify;

  a {
    color: $color-text;
  }
  a:hover {
    color: $color-main;
  }
}

/*  */
.news {
  margin: 4rem auto;
  max-width: 45rem;
}

.news__title {
  @include mq-prop(font-size,2rem,null,2.5rem);
  color: $color-main;
  font-family: $kaimin-sora-bold;
  margin: 0;
  text-align: center;
}

.news__en {
  font-family: $kaimin-sora-bold;
  margin: 0 0 1rem;
  text-align: center;
}

.news__list {
  margin-bottom: 2rem;
}

.news__link {
  background: $gray-200;
  color: $color-text;
  display: block;
  margin-bottom: 1px;
  padding: 1rem;
  text-align: justify;
  text-decoration: none;
}

.news__item {
  margin: 0;
}

.news__item dd {
  @include mq-prop(font-size,0.75rem,null,0.875rem);
  color: $color-main;
  font-weight: 700;
  margin: 0;
}

.news__item dt {
  @include mq-prop(font-size,0.875rem,null,1rem);
  font-weight: 400;
  text-decoration: underline;
}

.news__nav {
  @extend %nav-small;
  display: flex;
  justify-content: center;

  a {
    min-width: 15rem;
  }
  a::after {
    content: "";
  }
}

/*  */
.sns__message {
  @include mq-prop(font-size,1.25rem,null,1.5rem);
  color: $color-main;
  display: flex;
  font-family: $kaimin-sora-bold;
  justify-content: center;
  margin: 0 0 1rem;

  &::before,
  &::after {
    border: solid;
    border-color: currentColor transparent;
    border-width: 1.5em 0.125em 0;
    content: "";
    margin: 0 -0.125em;
    transform: rotate(-30deg);
    transform-origin: 50% 100%;
  }
  &::after {
    transform: rotate(30deg);
  }
}

.sns__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
}

.sns__col {
  @include mq-prop(width,100%,null,50%);
  padding: 2rem;
  position: relative;

  &::before {
    @include absolute(0);
    margin: 0 calc(50% - 50vw);
    z-index: -1;
  }
}

.sns__icon {
  margin: auto;
  width: 3rem;

  img {
    display: block;
    width: 100%;
  }
}

.sns__title {
  font-family: $kaimin-sora-bold;
  font-size: 1rem;
  margin: 0 0 1rem;
  text-align: center;
}

/*  */
.sns-ig {
  width: 100% !important;

  &::before {
    background: $color-main;
    content: "";
  }
}

.sns-ig .sns__icon {
  filter: invert(100%);
}

.sns-ig .sns__title {
  color: #ffffff;
}

/*  */
.sns-tw .sns__feed,
.sns-fb .sns__feed {
  position: relative;

  &::before {
    content: "";
    display: block;
    padding: calc(4 / 3 * 50%) 0;
  }
  iframe {
    @include position(0 null null 0);
    height: 100% !important;
    position: absolute !important;
  }
}

.sns-tw iframe {
  background: #ffffff;
  border: 1px solid $gray-400 !important;
  border-radius: 0.5rem;
}

/*  */
.sns-fb::before {
  background: url("../images/home/sns-fb-bg.png") 50%/1.5rem;
  content: "";

  @include mq-min(md) {
    background: none;
    border-left: 1px dotted $gray-400;
    margin: 2rem 0;
  }
}

.sns-fb .fb_iframe_widget,
.sns-fb .fb_iframe_widget span {
  display: block;
  height: auto !important;
  position: static;
}






















.image__list {
  margin: auto;
}

.thumb__list {
  margin: auto;
  margin-top: 1rem;
}

/*  */
.sns-ig .sns__feed {
  margin: -0.5rem;
}

#sb_instagram {
  padding-bottom: 0 !important;
}

.sns-tw iframe {
  border: none !important;
  width: 100% !important;
}

.news__en,
.sns__message {
  font-weight: 700;
}




@include mq-max(md) {
  .row-1 .about__col:first-child {
    margin-bottom: 1rem;
  }
  .row-1 .about__image {
    margin-left: 25%;
  }
  .row-2 .about__image {
    margin-right: 25%;
  }
}

.about__image {
  .row-2 &::before,
  .row-2 &::after {
    @include mq-prop(bottom,-1.5rem,null,-2rem);
    @include mq-prop(left,calc(100% - 4rem),null,50%);
    @include mq-prop(right,calc(-25% / 0.75),null,-3rem);
  }
  .row-2 &::after {
    z-index: -1;
  }
  .row-3 &::before,
  .row-3 &::after {
    @include mq-prop(bottom,-1.5rem,null,-2rem);
    @include position(null 2rem null 0);
  }
}

.about__caption {
  font-size: calc((100vw - 2rem) / 15.5);

  @media (min-width: calc(1.5rem * 15.5 + 2rem)) {
    @include mq-prop(font-size,1.5rem,null,null,1.75rem);
  }
}
